<template>
  <v-row class="features-details mt-6 mt-md-12">
    <v-col class="features-details__menu" cols="12" sm="4">
      <Sidebar
        :rootMenu="'features'"
        :items="menuItems"
        :itemChanged="handleSelectedItemChanged"
      />
    </v-col>
    <v-col class="features-details__content" cols="12" sm="8">
      <CardItem
        id="features"
        :title="$t(`support.items.features.menu.features.text`)"
      >
        <p class="mb-4">
          {{ $t("support.items.features.menu.features.content.line-1") }}:
        </p>
        <ol>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-2") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-3") }}
          </li>
          <li class="mb-8">
            {{ $t("support.items.features.menu.features.content.line-4") }}
          </li>
        </ol>
        <p class="mb-4">
          {{ $t("support.items.features.menu.features.content.line-5") }}:
        </p>
        <ol>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-2") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-3") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-6") }}
          </li>
          <li class="mb-8">
            {{ $t("support.items.features.menu.features.content.line-7") }}
          </li>
        </ol>
        <p class="mb-4">
          {{ $t("support.items.features.menu.features.content.line-8") }}:
        </p>
        <ol>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-2") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-3") }}
          </li>
          <li class="mb-8">
            {{ $t("support.items.features.menu.features.content.line-9") }}
          </li>
        </ol>
        <p class="mb-4">
          {{ $t("support.items.features.menu.features.content.line-10") }}:
        </p>
        <ol>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-11") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-12") }}
          </li>
          <li class="mb-8">
            {{ $t("support.items.features.menu.features.content.line-13") }}
          </li>
        </ol>
        <p class="mb-4">
          {{ $t("support.items.features.menu.features.content.line-14") }}:
        </p>
        <ol>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-2") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-3") }}
          </li>
          <li class="mb-8">
            {{ $t("support.items.features.menu.features.content.line-15") }}
          </li>
        </ol>
        <p class="mb-4">
          {{ $t("support.items.features.menu.features.content.line-16") }}:
        </p>
        <ol>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-2") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-3") }}
          </li>
          <li class="mb-4">
            {{ $t("support.items.features.menu.features.content.line-17") }}
          </li>
        </ol>
      </CardItem>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    Sidebar: () => import("../Shared/Sidebar.vue"),
    CardItem: () => import("../Shared/CardItem.vue"),
  },
  data(vm) {
    return {
      menuItems: [
        {
          key: "features",
          value: vm.$t("support.items.features.menu.features.text"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  watch: {
    searchParams: {
      handler({ criteria }) {
        if (criteria) {
          const menuItem = this.menuItems.find(
            (item) => item.value === criteria
          );

          if (menuItem) this.handleSelectedItemChanged(menuItem.key);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSelectedItemChanged(key) {
      this.$vuetify.goTo(`#${key}`);
    },
  },
};
</script>
